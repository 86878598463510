import React, {memo, useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import QnectButton from "../../qnect-ui/qnect-button";
import HomeIcon from "../../../assets/portfolio/icon_home_contents.svg";
import DmhIcon from "../../../assets/portfolio/icon_decorative_dmh.svg";
import MotorIcon from "../../../assets/portfolio/icon_decorative_motor.svg";
import BizIcon from "../../../assets/portfolio/icon_BIZ.svg";
import BoilerIcon from "../../../assets/portfolio/icon_boiler_explosion.svg";
import DirectorIcon from "../../../assets/portfolio/icon_directors_officers.svg";
import ContractorIcon from "../../../assets/portfolio/icon_contractor_all_risk.svg";
import ContractorPlantIcon from "../../../assets/portfolio/icon_Contractors_Plant_Eq.svg";
import ErectionIcon from "../../../assets/portfolio/icon_erection_all_risk.svg";
import GenericIcon from "../../../assets/portfolio/icon_generic.svg";
import EmployerIcon from "../../../assets/portfolio/icon_employer.svg";
import CommercialFireIcon from "../../../assets/portfolio/icon_commercial_fire.svg";
import FidelityIcon from "../../../assets/portfolio/icon_fidelity_gurantee.svg";
import GeneralPropIcon from "../../../assets/portfolio/icon_general_property.svg";
import GolferIcon from "../../../assets/portfolio/icon_golfers.svg";
import GroupMedicalIcon from "../../../assets/portfolio/icon_group_medical.svg";
import HouseHolderIcon from "../../../assets/portfolio/icon_householder.svg";
import ProfIndemnityIcon from "../../../assets/portfolio/icon_professional_indemnity.svg";
import IndustrialIcon from "../../../assets/portfolio/icon_industrial_risks.svg";
import MachineryIcon from "../../../assets/portfolio/icon_machinery_breakdown.svg";
import FreightIcon from "../../../assets/portfolio/icon_frieght_forwarder.svg";
import PersonalAccidentIcon from "../../../assets/portfolio/icon_personal_accident.svg";
import BroadformIcon from "../../../assets/portfolio/icon_broadform_liability.svg";
import TravelIcon from "../../../assets/portfolio/icon_travel.svg";
import TradeCreditIcon from "../../../assets/portfolio/icon_trade_credit.svg";
import GuaranteeBondIcon from "../../../assets/portfolio/icon_guarantee_bond.svg";
import AviationIcon from "../../../assets/portfolio/icon_aviation.svg";
import MedicalMalpracticeIcon from "../../../assets/portfolio/icon_medical_malpractice.svg";
import MarineCargoIcon from "../../../assets/portfolio/icon_marine_cargo.svg";
import SpecialRiskIcon from "../../../assets/portfolio/icon_special_risk.svg";
import WorkerInjuryIcon from "../../../assets/portfolio/icon_work_injury.svg";
import BurglaryIcon from "../../../assets/portfolio/BUR.svg";
import JewelleryIcon from "../../../assets/portfolio/JBK.svg";
import {lgDown} from "../../../ui/common/breakpoints";
import '../../../assets/styles/auto-renewal.css';
import {OPTIN} from "../../auto-renewal/auto-renewal-constants";

const StyledItem = styled.div`
  min-height: 307px;
  width: 350px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.06);
  margin: 30px 0 0 0;
  margin-left: ${({index}) => (index !== 0 ? "30px" : "0")};
  cursor: ${({clickable}) => (clickable ? "pointer" : "auto")};
  &:hover {
    transform: scale(1.05, 1.05);
  }
  ${lgDown`
    box-shadow: none;
    margin-left: 0;
    width: 320px;
    &:hover {
      transform: none;
    }
  `}
`;

const StyledItemInner = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
`;

const StyledItemTitle = styled.div`
  color: #0a1f44;
  font-family: Stag;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  padding-top: 18px;
  float: left;
  padding-bottom: 14px;
`;

const StyledQnectButtonWrapper = styled.div`
    float: none;
`;

const StyledQnectButton = styled(QnectButton)`
  background-color: #1d4fbb;
  height: 38px;
  width: 110px;
  box-shadow: none;
  color: #ffffff;
  font-family: "Inter-Medium", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDivider = styled(Divider)`
  width: 310px;
  margin: 0 auto !important;
  ${lgDown`
    width: 280px;
  `}
`;

const StyledItemIconRow = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: content-box;
  height: 50px;
  display: flex;
  justify-content: space-between;
`;

const StyledItemIconRowRightInner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 260px;
  ${lgDown`
    width: 218px;
  `}
`;

const StyledItemIconName = styled.div`
  color: #0a1f44;;
  font-family: "Inter-SemiBold", "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding-left: 12px;
  margin-top: 5px;
`;

const StyledItemIconAwaitingText = styled.div`
  color: #00c1cc;
  font-family: "Inter-SemiBold", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

const StyledItemIconExpireText = styled(StyledItemIconAwaitingText)`
  color: #d43900;
`;

const StyledItemIconActiveText = styled(StyledItemIconAwaitingText)`
  color: #11ac56;
`;

const StyledItemIconInactiveText = styled(StyledItemIconAwaitingText)`
  color: #A7AEBB;
`;

const StyledItemSubTitle = styled.div`
  color: #8a94a6;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  padding-top: 20px;
`;

const StyledItemData = styled.div`
  color: #0a1f44;
  font-family: "Inter-SemiBold", "Inter", sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding-top: 4px;
`;

const StyledItemPrice = styled.div`
  color: #0087d0;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  padding-top: 4px;
  letter-spacing: 0;
`;

const StyledItemPriceSub = styled.span`
  height: 20px;
  color: #8A94A6;
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;  
`;

const StyledItemIconSubTitle = styled(StyledItemSubTitle)`
  padding-top: 0;
  padding-left: 12px;
`;

const StyledItemInnerIcon = styled.div`
  display: flex;
  position: relative;
`;

const IconWrapper = styled.div`
  & > :first-child {
    width: 50px;
    height: 50px;
  }
`

const ButtonWrapper = (status, func) => {
    return (
        <StyledQnectButtonWrapper>
            <StyledQnectButton onClick={(e) => {
                e.stopPropagation();
                func();
            }}>{status}</StyledQnectButton>
        </StyledQnectButtonWrapper>
    );
};

const StyledToolTip = styled.div`
    font-family: Inter-Regular;
    font-size: 14px;
    background: rgba(10,16,27,0.65);
    color: #fff;
    //min-height: 129px;
    min-width: 200px;
    position: absolute;
    top: -53px;
    left: 0px;
    margin-top: 0px;
    padding: 5px 10px 5px 10px;
    text-align: left;
    line-height: 19px;
    white-space: pre-wrap;
    display: ${({showToolTip}) => showToolTip ? 'inline' : 'none'};
    width: 336px;
`;

const StyledPolicyCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const StyledPolicyCardStatus = styled.div `
    display: flex;
    border-radius: 5px;
    padding: 5px 10px;
`;
const StyledPolicyCardFooter = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
const StyledPolicyCardTotalPremium = styled.div `
    display: block;
`;
const StyledPolicyCardRenewButton = styled.div `
    margin-bottom: 4px;
    font-size: 14px;
    font-family: "Inter-Medium", sans-serif;
`;
const StyledPolicyPeriodContainer = styled.div `
    display: block;
`;
const StyledPolicyStatusContainer = styled.div `
    display: block;
    padding-bottom: 20px;
`;

const QuoteAndPolicyItem = ({index, data, onGoPolicy, onPay, onRenew,  country, cfg}) => {
    const clickable = data.type === "policy";
                                                // && (data.status === 'IF' || data.status === 'CA' ||
                                                //  data.status === 'PN' || data.status === 'MR' ||
                                                //  data.status === 'AR' || data.status === 'PR' ||
                                                //  data.status === 'FR' || data.status === 'SR' ||
                                                //  data.status === 'PP' || data.status === 'LA');

    const retrieveIcon = () => {

        if (data.purpose === 'WCA') {
            return country === 'SGP' ?  WorkerInjuryIcon : EmployerIcon;
        }

        switch (data.purpose) {
            case 'BIZ':
            case 'BPK':
            case 'OFF':
                return BizIcon;
            case 'BXP':
                return BoilerIcon;
            case 'DOL':
                return DirectorIcon;
            case 'CAR':
                return ContractorIcon;
            case 'CPM':
            case 'PGL':
                return ContractorPlantIcon;
            case 'EAR':
                return ErectionIcon;
            case 'HPK':
                return HomeIcon;
            case 'DMH':
                return DmhIcon;
            case 'MVA':
                return MotorIcon;
            case 'EPP':
                return EmployerIcon;
            case 'FFF':
                return CommercialFireIcon;
            case 'FGS':
                return FidelityIcon;
            case 'GEN':
                return GeneralPropIcon;
            case 'GOF':
                return GolferIcon;
            case 'GMB':
                return GroupMedicalIcon;
            case 'HHH':
                return HouseHolderIcon;
            case 'ICT':
            case 'PID':
                return ProfIndemnityIcon;
            case 'ISR':
                return IndustrialIcon;
            case 'MBD':
                return MachineryIcon;
            case 'MFF':
                return FreightIcon;
            case 'PAD':
                return PersonalAccidentIcon;
            case 'PLB':
                return BroadformIcon;
            case 'PIT':
            case 'PTR':
                return TravelIcon;
            case 'TRI':
                return TradeCreditIcon;
            case 'PBG':
            case 'PNI':
            case 'SUR':
                return GuaranteeBondIcon;
            case 'PPT':
            case 'PSA':
            case 'AVI':
                return AviationIcon;
            case 'MED':
            case 'PAM':
            case 'PAN':
                return MedicalMalpracticeIcon;
            case 'CAN':
            case 'MCA':
            case 'MCH':
            case 'MLL':
            case 'MPC':
            case 'OCN':
            case 'OCP':
                return MarineCargoIcon;
            case 'SPK':
                return SpecialRiskIcon;
            case 'WCD':
                return WorkerInjuryIcon;
            case 'BUR':
                return BurglaryIcon;
            case 'JBK':
                return JewelleryIcon;
            default:
                return GenericIcon;
        }
    }

    const retrieveDescription = () => {
        if(data.packageDescription){
            return data.packageDescription;
        }
        return cfg('productType.' + data.country + "." + data.catalogueCode).startsWith('productType.') ?
            cfg('productType.GENPRD') : cfg('productType.' + data.country + "." + data.catalogueCode);
    }

    const canRenew = () => {
        if(data.country === "MYS"){
            if(!cfg('productsForRenew.' + data.country).includes(data.catalogueCode)) {
                return false;
            }
        }
        if ((data.country === 'SGP' || data.country === 'HKG' || data.country === 'HGI' || data.country === 'MYS') &&
            ((data.status === "AR" || data.status === "FR" || data.status === "SR" || data.status === "PP" ) &&
                data.renewalFlag === 'Y')) {
            return true;
        }

        return false;
    }

    const showPolicyStatusCode = () => {
        if (cfg('validPolicyStatusCodes.' + data.country + '.expiresSoon').includes(data.status)) {
            return (
                <StyledPolicyCardStatus className="status-label expires-soon">
                    {/*<img src={AlertIcon}/>*/}
                    <StyledItemIconExpireText>{ cfg('labels.portfolio.statusExpire') }</StyledItemIconExpireText>
                </StyledPolicyCardStatus>
            );
        } else if (cfg('validPolicyStatusCodes.' + data.country + '.active').includes(data.status)) {
            return (
                <StyledPolicyCardStatus className="status-label active">
                    {/*<img src={CheckIcon}/>*/}
                    <StyledItemIconActiveText>{ cfg('labels.portfolio.statusActive') }</StyledItemIconActiveText>
                </StyledPolicyCardStatus>
            )
        } else if (cfg('validPolicyStatusCodes.' + data.country + '.inactive').includes(data.status)) {
            return (
                <StyledPolicyCardStatus className="status-label inactive">
                    {/*<img src={DismissIcon}/>*/}
                    <StyledItemIconInactiveText>{ cfg('labels.portfolio.statusInactive') }</StyledItemIconInactiveText>
                </StyledPolicyCardStatus>
            )
        } else {
            return (<></>);
        }
    }

    const [showToolTip, updateTooltipState] = useState(false);

    const mouseEnter = () => {
        if (cfg('validPolicyStatusCodes.' + data.country + '.expiresSoon').includes(data.status) &&
            data.country === 'HGI') {
            updateTooltipState(true);
        }
    };

    const mouseLeave = () => {
        updateTooltipState(false);
    };

    return (
        <StyledItem index={index} clickable={clickable} onClick={() => clickable && onGoPolicy()} className={(cfg('validPolicyStatusCodes.' + data.country + '.expiresSoon').includes(data.status)) ? "expiring" : ""}>
            <StyledItemInner>
                <StyledPolicyCardHeader>
                    <StyledItemTitle>{(data.type === "quote" && cfg('labels.portfolio.quote')) || (data.type === "policy" && cfg('labels.portfolio.policy'))}</StyledItemTitle>
                    {showPolicyStatusCode()}
                </StyledPolicyCardHeader>
                <StyledDivider/>
                <StyledItemIconRow>
                    <IconWrapper>
                        <img src={retrieveIcon()}/>
                    </IconWrapper>
                    <div>
                        <StyledItemIconRowRightInner>
                            <StyledItemIconSubTitle>{data.policyNumber}</StyledItemIconSubTitle>
                            <StyledItemInnerIcon onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                                <StyledToolTip showToolTip={showToolTip}>
                                    {cfg('labels.portfolio.renewalToolTip')}
                                </StyledToolTip>
                            </StyledItemInnerIcon>
                        </StyledItemIconRowRightInner>
                        <StyledItemIconName>
                            {retrieveDescription()}
                        </StyledItemIconName>
                    </div>
                </StyledItemIconRow>
                <StyledPolicyPeriodContainer>
                    <StyledItemSubTitle> { cfg('labels.portfolio.policyPeriod') } </StyledItemSubTitle>
                    <StyledItemData>{data.period}</StyledItemData>
                </StyledPolicyPeriodContainer>
                { data.eligibleForAutoRenewal &&
                <StyledPolicyStatusContainer>
                    <StyledItemSubTitle>{cfg('labels.autoRenewal.statusTitleDisplay')}</StyledItemSubTitle>
                    {
                        data.autoRenewalStatus === OPTIN ?
                        <StyledItemData>{cfg('labels.autoRenewal.statusOnDisplay')}</StyledItemData> :
                        <StyledItemData>{cfg('labels.autoRenewal.statusOffDisplay')}</StyledItemData>
                    }
                </StyledPolicyStatusContainer>
                }
                <StyledDivider/>
                <StyledPolicyCardFooter>
                    <StyledPolicyCardTotalPremium>
                        <StyledItemSubTitle>{ cfg('labels.portfolio.totalPremium') }</StyledItemSubTitle>
                        <StyledItemPrice>
                            {data.country === 'MYS' ? data.price.replace('$', 'MYR ') : data.price}
                            {
                                (country === 'SGP' && data?.price?.length > 13) ? <br/> : null
                            }
                        </StyledItemPrice>
                    </StyledPolicyCardTotalPremium>

                    <StyledPolicyCardRenewButton>{canRenew() ? ButtonWrapper("Renew", onRenew) : null}</StyledPolicyCardRenewButton>
                </StyledPolicyCardFooter>

            </StyledItemInner>
        </StyledItem>
    );
};

QuoteAndPolicyItem.propTypes = {
    data: PropTypes.object,
    onPay: PropTypes.func,
    onRenew: PropTypes.func,
    onGoPolicy: PropTypes.func,
    index: PropTypes.number,
};


export default memo(QuoteAndPolicyItem);
