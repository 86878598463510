import React, {useEffect, useState} from "react";
import {useHistory, withRouter} from "react-router-dom";
import EClaimsImage from "../../assets/portfolio/eclaims.png";
import InsuranceAwardsImage from "../../assets/portfolio/insurance_awards.png";
import InsuranceAwardsSgpImage from "../../assets/portfolio/insurance_awards_sgp.png";
import NeedHelpImage from "../../assets/portfolio/needHelp.png";
import useConfig from "../qnect-ui/qnect-config";
import BlankImage from "../../assets/profile/avatar_image_placeholder.svg";
import Header from "../../components/custom/portfolio/header";
import QuoteAndPolicyItem from "../../components/custom/portfolio/quote-and-policy-item";
import WhatsNewsItem from "../../components/custom/portfolio/whats-news-item";
import LocatePolicyDialog from "../../components/custom/portfolio/locate-policy-dialog";
import LocatePolicyFormDialog from "../../components/custom/portfolio/locate-policy-form-dialog";
import LocatePolicyHelpDialog from "../../components/custom/portfolio/locate-policy-help-dialog";
import LocatePolicySuccessDialog from "../../components/custom/portfolio/locate-policy-success-dialog";
import ClaimRegisteredDialog from "../../components/custom/portfolio/claim-registered-dialog";
import HowToFindPolicyDialog from "../../components/custom/portfolio/how-to-find-policy-dialog";
import AreYouStillThereDialog from "../../components/custom/portfolio/are-you-still-there-dialog";
import Pagination from "../../components/custom/portfolio/pagination";
import Hidden from "@material-ui/core/Hidden";
import {connect} from "react-redux";
import AfterRegDialog from "../custom/registration/after-reg-dialog";
import moment from "moment";
import {
    _getUser,
    _getUserDetailById,
    _getUserDetailsFromToken,
    _updateUserSkipFlash,
    _retrievePolicyList,
    _preparePayment, _prepareRenewal, _changePassword
} from "../../store/user/service";
import {
    _clearState,
    _generatePolicyTokenFromPortfolioScreen,
    _getCountry,
    _getLang, _getSkipFlashDone,
    _hideSpinner, _setCpToken, _setPolicyToken, _setSkipFlashDoneToTrue, _showError,
    _showSpinner,_getBanners, _showModal, _hideModal
} from "../../store/system/service";
import {
    HeaderWrapper,
    StyledBottomBackground,
    StyledItemWrapper,
    StyledLocatePolicyWrapper,
    StyledMiddleBackground, StyledNewsSwipeable,
    StyledSectionWrapper, StyledSwipeable,
    StyledTitleRow,
    StyledTopBackground, StyledWhatsNewsTitle,
    StyledMessage
} from "./portfolio-style";
import {_storePolicyPayment} from "../../store/policy-payment/service";
import {_getPolicy, _storePolicy} from "../../store/policy/service";
import formatCurrency from "../../utils/format-currency";
import * as TagManager from "react-gtm-module";
import {_sendLog, showGstDisclaimer} from "../../utils/utility";
import Carousel from "../custom/carousel/carousel";
import ExpiredPasswordDialog from "../custom/portfolio/expired-password-dialog";

// -----------------------------------------mock/fake data-----------------------------------------
const Portfolio = props => {
    const history = useHistory();
    const cfg = useConfig(props.lang);
    const cfg_en = useConfig('en');

    const pendingItemPerPage = 4;
    const quoteItemPerPage = 3;
    const SESSION_TIMEOUT = props.country === 'MYS' ? 60 * 15 : 60 * 5;
    const [bannerList, setBannerList] = useState([]);
    const [isCarouselPlaying, setIsCarouselPlaying] = useState(false);
    const eclaimsLink = () => {
        if (props.country === 'HKG') {
            return (
                props.lang === 'zh' ?
                    'https://qbe.com/hk/zh-hk/intro-to-eclaims' : 'https://qbe.com/hk/en/intro-to-eclaims')
        } else if (props.country === 'SGP') {
            return 'https://www.qbe.com/sg/eclaims';
        } else {
            return 'https://claims.qbe.com/claims/?country=MYS';
        }
    }

    const mockWhatsNewsItemData = [
        {
            title: cfg('whats_new.insurance_awards.title'),
            content: cfg('whats_new.insurance_awards.content'),
            image: InsuranceAwardsImage,
            linkText: cfg('whats_new.insurance_awards.linkText'),
            link: props.lang === 'zh' ?
                'https://www.qbe.com/hk/zh-hk/newsroom/useful-articles/protecting-your-home-sweet-home'
                : 'https://www.qbe.com/hk/en/newsroom/useful-articles/protecting-your-home-sweet-home',
            eventCategory: 'insurance_awards'
        },
        {
            title: cfg('whats_new.eClaims.title'),
            content: cfg('whats_new.eClaims.content'),
            image: EClaimsImage,
            link: eclaimsLink(),
            eventCategory: 'eclaims'
        },
        {
            title: cfg('whats_new.needHelp.title'),
            content: cfg('whats_new.needHelp.content'),
            image: NeedHelpImage,
            link: 'https://www.qbe.com/hk/en/contact',
            eventCategory: 'needHelp'
        }
    ];

    const mockWhatsNewsItemDataSgp = [
        {
            title: cfg('whats_new.insurance_awards_sgp.title'),
            content: cfg('whats_new.insurance_awards_sgp.content'),
            image: InsuranceAwardsSgpImage,
            link: 'https://www.facebook.com/watch/?v=375007373689665',
            eventCategory: 'insurance_awards'
        },
        {
            title: cfg('whats_new.eClaims_sgp.title'),
            content: cfg('whats_new.eClaims_sgp.content'),
            image: EClaimsImage,
            link: eclaimsLink(),
            eventCategory: 'eclaims'
        },
        {
            title: cfg('whats_new.needHelp_sgp.title'),
            content: cfg('whats_new.needHelp_sgp.content'),
            image: NeedHelpImage,
            link: 'https://www.qbe.com/sg/contact',
            eventCategory: 'needHelp'
        }
    ];

    const mockWhatsNewsItemDataMys = [
        {
            title: cfg('whats_new.insurance_awards_mys.title'),
            content: cfg('whats_new.insurance_awards_mys.content'),
            image: InsuranceAwardsImage,
            link: 'https://www.qbe.com/my/personal-insurance',
            linkText: cfg('whats_new.insurance_awards_mys.linkText'),
            eventCategory: 'insurance_awards'
        },
        {
            title: cfg('whats_new.eClaims_mys.title'),
            content: cfg('whats_new.eClaims_mys.content'),
            image: EClaimsImage,
            linkText: cfg('whats_new.eClaims_mys.linkText'),
            link: eclaimsLink(),
            eventCategory: 'eclaims'
        },
        {
            title: cfg('whats_new.needHelp_mys.title'),
            content: cfg('whats_new.needHelp_mys.content'),
            image: NeedHelpImage,
            linkText: cfg('whats_new.needHelp_mys.linkText'),
            link: 'https://www.qbe.com/my/contact',
            eventCategory: 'needHelp'
        }
    ];

    const [state, setState] = React.useState({
        openLearnMoreDialog: false,
        openLocateDialog: false,
        openLocateFormDialog: false,
        openLocateHelpDialog: false,
        openLocateSuccessDialog: false,
        openClaimDialog: false,
        openFindPolicyDialog: false,
        openAreYouStillThereDialog: false,
        currentPendingItemPage: 1,
        currentQuoteItemPage: 1,
        openAfterRegDialog: false
    });

    const [sessionTimer, setSessionTimer] = useState(SESSION_TIMEOUT);
    const [sessionTimerTimeout, setSessionTimerTimeout] = useState(null);
    const [quoteAndPolicyData, setQuoteAndPolicyData] = useState();
    const [isPasswordExpired, setIsPasswordExpired] = useState(false);
    const [passwordMinLength, setPasswordMinLength] = useState();

    useEffect(() => {
        clearTimeout(sessionTimerTimeout);
        if (sessionTimer > 0) {
            let timeoutId = setTimeout(() => {
                setSessionTimer(sessionTimer - 1);
            }, 1000);
            setSessionTimerTimeout(timeoutId);
        } else {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'popup',
                    popupPath: '/portfolio',
                    popupTitle: 'Are you still there?'
                }
            });
            setState(prev => ({...prev, openAreYouStillThereDialog: true}));
        }
    }, [sessionTimer]);

    useEffect(() => {

        TagManager.dataLayer({
            dataLayer: {
                event: 'vpageview',
                pageStep: 'Portfolio',
                vPath: '/portfolio'
            }
        });

    }, []);

    useEffect(() => {

        if (props.user?.userId && !props.user?.policyPortfolio.length > 0) {
            console.log('loading retrievePolicyList');
            showSplashScreen(props.user.skipFlash);
            props.showSpinner();
            props.retrievePolicyList().then((resp) => {
                props.user.policyPortfolio = resp.policyPortfolio;
                populateQuotePolicyItems(props.user.policyPortfolio);
                props.hideSpinner();
                props.setCpToken(resp.token.token);
                setIsCarouselPlaying(true);

                if(resp.isPasswordExpired) {
                    handleCloseFlash();
                }

                setIsPasswordExpired(resp.isPasswordExpired);
                setPasswordMinLength(resp.passwordMinLength);
            });
        }
        else {
            props.showSpinner();
            props.retrievePolicyList().then((resp) => {
                props.user.policyPortfolio = resp.policyPortfolio;
                populateQuotePolicyItems(props.user.policyPortfolio);
                props.hideSpinner();
                props.setCpToken(resp.token.token);

                setIsPasswordExpired(resp.isPasswordExpired);
                setPasswordMinLength(resp.passwordMinLength);
            });
        }

    }, []);

    useEffect(() => {
        document.addEventListener('mousemove', () => {
            resetSessionTimer();
        });
        document.addEventListener('keypress', () => {
            resetSessionTimer();
        });

    }, []);

    const showSplashScreen = (skipFlash) => {
        if (skipFlash !== 'Y' && !props.skipFlashDone && props.country !== 'SGP') {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'popup',
                    popupPath: '/portfolio',
                    popupTitle: 'Access Policy Information'
                }
            });
            setState(prev => ({...prev, openAfterRegDialog: true}))
        }
    }

    const resetSessionTimer = () => {
        clearTimeout(sessionTimerTimeout);
        setSessionTimer(SESSION_TIMEOUT);
    }

    const populateQuotePolicyItems = (policyPortfolio) => {
        let quoteAndPolicyArray = [];
        policyPortfolio.forEach(item => {
            if (validateIfPolicyIsAllowedToBeShown(item)){
                let getPremiumFormat = (premium, hasGST) => {
                    if (props.country === 'HKG') {
                        return 'HKD' + formatCurrency(premium);
                    } else if (props.country === 'SGP') {
                        return 'S' + formatCurrency(premium) + (hasGST === 'Y' ? ' w/GST' : ' GST Not Applicable');
                    } else {
                        return formatCurrency(premium);
                    }
                }

                let newObject = {
                    type: isNaN(item.policyNumber.charAt(0)) ? 'policy' : 'quote',
                    status: item.statusCode,
                    origStatus: item.statusCode,
                    policyNumber: item.policyNumber,
                    transactionNumber: item.transactionNumber,
                    purpose: item.contractType,
                    effectiveDate: item.inceptionDate,
                    period: moment(item.origInceptionDate).format('DD/MM/YYYY') +
                        ' - ' + moment(item.origExpiryDate).format('DD/MM/YYYY'),
                    price: getPremiumFormat(item.totalPremiumDue, item?.hasGST),
                    renewalFlag: item.renewalFlag,
                    paymentFlag: item.oustandingPremiumFlag,
                    paymentOutstanding: item.oustandingPremium,
                    premiumAmount: item.grossPremium,
                    country: item.headerCountry ? item.headerCountry : props.country,
                    catalogueCode: item.catalogueCode,
                    totalPremiumDue: item.totalPremiumDue,
                    hasGST: item?.hasGST,
                    autoRenewalStatus: item.autoRenewalStatus,
                    eligibleForAutoRenewal: item.eligibleForAutoRenewal,
                    packageCode: item.packageCode,
                    packageDescription: item.packageDescription
                }

                quoteAndPolicyArray.push(newObject);
            }
        })

        setQuoteAndPolicyData(quoteAndPolicyArray);
    }

    // include only if catalogue code and status code is allowed to be shown
    const validateIfPolicyIsAllowedToBeShown = (policy) => {
        let invalidCatalogueCodes = cfg('invalidCatalogueCodes.' + policy.headerCountry);
        let validPolicyStatus = cfg('validStatusCodes.' + policy.headerCountry);

        if (invalidCatalogueCodes.includes(policy.catalogueCode) || !validPolicyStatus.includes(policy.statusCode)
            || cfg('productType.' + policy.headerCountry + '.' + policy.catalogueCode).startsWith('productType.')) {
            return false;
        }

        return true;
    }

    const quoteItemPageClick = direction => {
        if (direction === "front")
            setState(prev => ({
                ...prev,
                currentQuoteItemPage: state.currentQuoteItemPage + 1
            }));
        else
            setState(prev => ({
                ...prev,
                currentQuoteItemPage: state.currentQuoteItemPage - 1
            }));
    };

    const indexOfLastQuoteItem = state.currentQuoteItemPage * quoteItemPerPage;
    const indexOfFirstQuoteItem = indexOfLastQuoteItem - quoteItemPerPage;
    const currentQuoteItems = quoteAndPolicyData ? quoteAndPolicyData.slice(
        indexOfFirstQuoteItem,
        indexOfLastQuoteItem
    ) : null;

    const handleCloseFlash = () => {
        setState(prev => ({...prev, openAfterRegDialog: false}));
        props.setSkipFlashDoneToTrue();
    };

    const handleGoToPolicyDetail = (policyObject) => {

        const eventCategory = cfg_en('productType.' + policyObject.country + '.' + policyObject.catalogueCode);

        TagManager.dataLayer({
            dataLayer: {
                event: 'Policy-Click',
                eventCategory: eventCategory.replaceAll(/\s/g, '-')
            }
        })

        props.showSpinner();
        props.generatePolicyTokenFromPortfolioScreen(policyObject.policyNumber, policyObject.country).then(
            resp => {
                props.hideSpinner();
                if (resp !== 'FAILED') {
                    props.setCpToken(resp.token);
                    props.setPolicyToken(resp.token);

                    props.policy.origPolicyPeriod = policyObject.period;
                    props.policy.origStatus = policyObject.status;
                    props.policy.origAmount = policyObject.totalPremiumDue;
                    props.policy.price = policyObject.price;
                    props.policy.effectiveDate = policyObject.effectiveDate;
                    props.policy.renewalFlag = policyObject.renewalFlag;
                    props.policy.eligibleForAutoRenewal = policyObject.eligibleForAutoRenewal;
                    props.policy.autoRenewalStatus = policyObject.autoRenewalStatus;
                    props.policy.packageCode = policyObject.packageCode;
                    props.policy.packageDescription = policyObject.packageDescription;
                    props.storePolicy(props.policy);

                    console.log("redirecting to policy");

                    history.push('/policy');

                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'vpageview',
                            pageStep: 'Policy Details - ' + eventCategory,
                            vPath: '/policy',
                            vProduct: '/' + eventCategory,
                            vPolicyNo: policyObject.policyNumber
                        }
                    });
                }
            }, (error) => {
              props.hideSpinner();
              console.error('generatePolicyTokenFromPortfolioScreen has failed', error);
            }
        )
    }

    const handleRenew = (policy) => {
        props.showSpinner();
        _sendLog(' portfolio.js - handleRenew(): calling prepareRenewal()... policyNumber = ' + policy.policyNumber + ', transactionNumber = ' + policy.transactionNumber + ', effectiveDate = ' + policy.effectiveDate + ', premium = '+ policy.paymentOutstanding ? policy.paymentOutstanding : policy.premiumAmount + ', country = '+ policy.country, 'info');
        props.prepareRenewal(policy.policyNumber, policy.transactionNumber, policy.effectiveDate, policy.paymentOutstanding ? policy.paymentOutstanding : policy.premiumAmount, policy.country).then((resp) => {
            props.hideSpinner();
            if (resp.policyPayment.errorMessage) {
                props.showError([resp.policyPayment.errorMessage]);
                _sendLog(' portfolio.js - handleRenew() - prepareRenewal(): errorMessage:\n\n' + JSON.stringify(resp.policyPayment.errorMessage));
            }
            else {
                resp.policyPayment.hasGST = policy.hasGST;
                props.storePolicyPayment(resp.policyPayment)

                /** store these data so when redirecting after payment finish it will get correct data */
                props.policy.origPolicyPeriod = policy.period;
                props.policy.origAmount = policy.totalPremiumDue;
                props.policy.price = policy.price;
                props.policy.effectiveDate = policy.effectiveDate;
                props.storePolicy(props.policy);

                history.push("/renewal-notice");
            }
        }, (error) => {
            props.hideSpinner();
            history.push("/portfolio");
            props.showError(['Preparing renewal has failed']);
            _sendLog(' portfolio.js - handleRenew() - prepareRenewal(): exception:\n\n' + JSON.stringify(error));
        })

    }

    const handlePayNow = (policy) => {
        props.showSpinner();
        _sendLog(' portfolio.js - handlePayNow(): calling preparePayment()... policyNumber = ' + policy.policyNumber + ', transactionNumber = ' + policy.transactionNumber + ', effectiveDate = ' + policy.effectiveDate + ', premium = '+ policy.paymentOutstanding ? policy.paymentOutstanding : policy.premiumAmount + ', country = '+ policy.country, 'info');
        props.preparePayment(policy.policyNumber, policy.transactionNumber, policy.effectiveDate, policy.paymentOutstanding ? policy.paymentOutstanding : policy.premiumAmount, policy.country).then((resp) => {
            props.hideSpinner();
            props.storePolicyPayment(resp.policyPayment);
            props.history.push("/payment-detail");
        }, (error) => {
            props.hideSpinner();
            history.push("/portfolio");
            props.showError(['Preparing payment has failed']);
            _sendLog(' portfolio.js - handlePayNow() - preparePayment(): exception:\n\n' + JSON.stringify(error));
        })
    }

    const handleLogout = () => {
        props.clearState();
        props.history.push('/login');
    }

    useEffect(() => {
        props.getBanners(props.country).then((response) =>  {
            setBannerList(response);
        });
    },[props.cpToken]);
    return (
        <div style={{overflowY: "auto !important"}}>
            <StyledTopBackground country={props.country}>
                <HeaderWrapper>
                    <Header
                        imgSrc={BlankImage}
                        cfg={cfg}
                        name={props.user.userName}
                    />
                </HeaderWrapper>
                <Carousel bannerList={bannerList} props={props} isPlaying={isCarouselPlaying}/>
            </StyledTopBackground>

            <StyledMiddleBackground>
                <StyledSectionWrapper>
                    {quoteAndPolicyData && quoteAndPolicyData.length > 0 && (
                        <StyledSectionWrapper>
                            <StyledTitleRow>
                                {cfg("labels.policyLabel")}
                                <StyledLocatePolicyWrapper
                                    onClick={() =>
                                        setState(prev => ({...prev, openLocateDialog: true}))
                                    }
                                >
                                </StyledLocatePolicyWrapper>
                            </StyledTitleRow>
                            <StyledItemWrapper>
                                <Hidden lgUp>
                                    <StyledSwipeable>
                                        {quoteAndPolicyData.map((item, index) => (
                                            <QuoteAndPolicyItem
                                                key={index}
                                                index={index}
                                                data={item}
                                                onGoPolicy={() => handleGoToPolicyDetail(item)}
                                                onPay={() => handlePayNow(item)}
                                                onRenew={() => handleRenew(item)}
                                                country={props.country}
                                                cfg={cfg}
                                            />
                                        ))}
                                    </StyledSwipeable>
                                </Hidden>

                                <Hidden mdDown>
                                    {currentQuoteItems.map((item, index) => (
                                        <QuoteAndPolicyItem
                                            key={index}
                                            index={index}
                                            data={item}
                                            onGoPolicy={() => handleGoToPolicyDetail(item)}
                                            onPay={() => handlePayNow(item)}
                                            onRenew={() => handleRenew(item)}
                                            country={props.country}
                                            cfg={cfg}
                                        />
                                    ))}
                                </Hidden>
                            </StyledItemWrapper>

                            <Hidden mdDown>
                                {quoteAndPolicyData.length > quoteItemPerPage && (
                                    <Pagination
                                        allItems={quoteAndPolicyData}
                                        currentPage={state.currentQuoteItemPage}
                                        currentItems={currentQuoteItems}
                                        itemPerPage={quoteItemPerPage}
                                        firstItemIndex={indexOfFirstQuoteItem}
                                        lastItemIndex={indexOfLastQuoteItem}
                                        action={direction => quoteItemPageClick(direction)}
                                    />
                                )}
                            </Hidden>
                            <StyledMessage>
                                {showGstDisclaimer(props.country) ? cfg('labels.portfolio.gstDisclaimer') : null}
                            </StyledMessage>
                            <StyledMessage>{cfg('labels.portfolio.message')}</StyledMessage>
                        </StyledSectionWrapper>
                    )}
                </StyledSectionWrapper>
            </StyledMiddleBackground>

            <StyledBottomBackground>
                <StyledSectionWrapper>
                    <StyledWhatsNewsTitle>{ cfg('whats_new.title')}</StyledWhatsNewsTitle>
                    <StyledItemWrapper>
                        <Hidden lgUp>
                            <StyledNewsSwipeable>
                                {
                                    props.country === 'SGP' &&
                                    mockWhatsNewsItemDataSgp.map((item, index) => (
                                        <WhatsNewsItem key={index} index={index} {...item} />
                                    ))
                                }
                                {
                                    props.country === 'HKG' &&
                                    mockWhatsNewsItemData.map((item, index) => (
                                        <WhatsNewsItem key={index} index={index} {...item} />
                                    ))
                                }
                                {
                                    props.country === 'MYS' &&
                                    mockWhatsNewsItemDataMys.map((item, index) => (
                                        <WhatsNewsItem key={index} index={index} {...item} />
                                    ))
                                }
                            </StyledNewsSwipeable>
                        </Hidden>
                        <Hidden mdDown>
                                {
                                    props.country === 'SGP' &&
                                    mockWhatsNewsItemDataSgp.map((item, index) => (
                                        <WhatsNewsItem key={index} index={index} {...item} />
                                    ))
                                }
                                {
                                    props.country === 'HKG' &&
                                    mockWhatsNewsItemData.map((item, index) => (
                                        <WhatsNewsItem key={index} index={index} {...item} />
                                    ))
                                }
                                {
                                    props.country === 'MYS' &&
                                    mockWhatsNewsItemDataMys.map((item, index) => (
                                        <WhatsNewsItem key={index} index={index} {...item} />
                                    ))
                                }
                        </Hidden>
                    </StyledItemWrapper>
                </StyledSectionWrapper>
            </StyledBottomBackground>

            <LocatePolicyDialog
                open={state.openLocateDialog}
                onClose={() => setState(prev => ({...prev, openLocateDialog: false}))}
                onOther={() =>
                    setState(prev => ({...prev, openFindPolicyDialog: true}))
                }
                onNext={() =>
                    setState(prev => ({...prev, openLocateFormDialog: true}))
                }
                cfg={cfg}
                lang={'en'}
            />
            <LocatePolicyFormDialog
                open={state.openLocateFormDialog}
                onClose={() =>
                    setState(prev => ({...prev, openLocateFormDialog: false}))
                }
                onOther={() =>
                    setState(prev => ({...prev, openLocateHelpDialog: true}))
                }
                onSubmit={() =>
                    setState(prev => ({...prev, openLocateSuccessDialog: true}))
                }
                cfg={cfg}
            />
            <LocatePolicyHelpDialog
                open={state.openLocateHelpDialog}
                onClose={() =>
                    setState(prev => ({...prev, openLocateHelpDialog: false}))
                }
                onBack={() =>
                    setState(prev => ({...prev, openLocateFormDialog: true}))
                }
            />
            <LocatePolicySuccessDialog
                open={state.openLocateSuccessDialog}
                onClose={() =>
                    setState(prev => ({...prev, openLocateSuccessDialog: false}))
                }
                cfg={cfg}
            />
            <ClaimRegisteredDialog
                open={state.openClaimDialog}
                onClose={() => setState(prev => ({...prev, openClaimDialog: false}))}
            />
            <HowToFindPolicyDialog
                open={state.openFindPolicyDialog}
                onClose={() =>
                    setState(prev => ({...prev, openFindPolicyDialog: false}))
                }
                onBack={() => setState(prev => ({...prev, openLocateDialog: true}))}
                cfg={cfg}
            />

            <AreYouStillThereDialog
                open={state.openAreYouStillThereDialog}
                onLogout={() => {
                    handleLogout();
                }}
                onClose={() => {
                    setState(prev => ({...prev, openAreYouStillThereDialog: false}));
                }}
                cfg={cfg}
                lang={props.lang}
                country={props.country}
            />

            <AfterRegDialog
                open={state.openAfterRegDialog}
                onClose={handleCloseFlash}
                skipFlashUpdate={props.updateUserSkipFlash}
                cfg={cfg}
                country={props.country}
            />

            <ExpiredPasswordDialog open={isPasswordExpired}
                    props={props}
                    passwordMinLength={passwordMinLength}>
            </ExpiredPasswordDialog>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        user: _getUser(state),
        lang: _getLang(state),
        skipFlashDone: _getSkipFlashDone(state),
        policy: _getPolicy(state)
    };
}

const mapDispatchToProps = {
    getUserDetailById: _getUserDetailById,
    updateUserSkipFlash: _updateUserSkipFlash,
    retrievePolicyList: _retrievePolicyList,
    preparePayment: _preparePayment,
    prepareRenewal: _prepareRenewal,
    storePolicyPayment: _storePolicyPayment,
    generatePolicyTokenFromPortfolioScreen: _generatePolicyTokenFromPortfolioScreen,
    getUserDetailsFromToken: _getUserDetailsFromToken,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showError: _showError,
    clearState: _clearState,
    setSkipFlashDoneToTrue: _setSkipFlashDoneToTrue,
    setCpToken: _setCpToken,
    setPolicyToken: _setPolicyToken,
    storePolicy: _storePolicy,
    getBanners: _getBanners,
    changePassword: _changePassword,
    showModal: _showModal,
    hideModal: _hideModal
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Portfolio));
